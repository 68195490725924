<template>
  <div>
    <div
      v-if="due"
      :class="style"
      class="badge rounded-pill float-start"
    >{{ due }}</div>
  </div>
</template>

<script>
export default {
  name: 'UserProgramDue',
  props: {
    dueDate: {
      type: Number,
      default: null,
    },
  },
  computed: {
    due() {
      const { dueDate } = this;
      if (dueDate === null) return null;
      if (dueDate === 0) return 'Today';
      if (dueDate > 0) return `in ${dueDate} day${dueDate > 1 ? 's' : ''}`;
      return `${Math.abs(dueDate)} day${dueDate > 1 ? 's' : ''} ago`;
    },
    style() {
      const { dueDate } = this;
      if (dueDate === null || dueDate <= 0) return 'bg-danger';
      return 'bg-primary';
    },
  },
};
</script>
